import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/gallery/givinghelpdesk",
  "project": "Giving Help Desk",
  "projectID": "givinghelpdesk",
  "projectURL": "https://givinghelpdesk.com",
  "projectDate": "2020-03-21"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Gallery = makeShortcode("Gallery");
const GalleryImg = makeShortcode("GalleryImg");
const GalleryVid = makeShortcode("GalleryVid");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas, nesciunt illum quos id quo repellat dolorum totam sed voluptatem, numquam harum accusamus delectus, soluta laborum? Minima libero atque rerum deserunt? */
    }
    <Gallery mdxType="Gallery">
  <GalleryImg src="givinghelpdesk_home.png" alt="Home page screenshot" title="Home" mdxType="GalleryImg" />
  <GalleryVid file="givinghelpdesk_theme" mdxType="GalleryVid" />
  <GalleryVid file="givinghelpdesk_search" mdxType="GalleryVid" />
  <GalleryVid file="givinghelpdesk_video-loading" mdxType="GalleryVid" />
  <GalleryImg src="givinghelpdesk_video-courses-start.png" alt="Video Courses Start screenshot" title="Video Courses Start" mdxType="GalleryImg" />
  <GalleryImg src="givinghelpdesk_video-courses-next.png" alt="Video Courses Next screenshot" title="Video Courses Next" mdxType="GalleryImg" />
    </Gallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      